import PropTypes from 'prop-types';
import { interactiveTypesHash } from '@culture/utils/helpers/interactive';
import { statusesHash } from '@culture/utils/helpers/statuses';

const statusesValues = Object.values(statusesHash);

export const StatusPropType = PropTypes.oneOf(statusesValues);

const baseProps = {
	_id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	thumbnailFile: PropTypes.object
};

export const NavigationItemPropType = PropTypes.shape({
	...baseProps,
	url: PropTypes.string,
	children: PropTypes.array
});

export const NavigationPropType = PropTypes.arrayOf(NavigationItemPropType);

export const MetaPropType = PropTypes.shape({
	title: PropTypes.string.isRequired,
	headingTitle: PropTypes.string,
	description: PropTypes.string.isRequired,
	keywords: PropTypes.string,
	og: PropTypes.shape({
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		thumbnailFile: PropTypes.object
	})
});

export const RubricPropType = PropTypes.shape({
	_id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	meta: MetaPropType,
	headingTitle: PropTypes.string,
	name: PropTypes.string,
	text: PropTypes.string,
	active: PropTypes.bool,
	hidden: PropTypes.bool,
	level: PropTypes.number,
	path: PropTypes.string
});

const RubricTreeShape = {
	_id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	level: PropTypes.number.isRequired,
	parentId: PropTypes.number
};
RubricTreeShape.children = PropTypes.arrayOf(PropTypes.shape(RubricTreeShape));

export const RubricTreePropType = PropTypes.shape(RubricTreeShape);

export const EntityPropType = PropTypes.shape(baseProps);

export const FilePropType = PropTypes.shape({
	_id: PropTypes.number.isRequired,
	title: PropTypes.string,
	type: PropTypes.string.isRequired,
	height: PropTypes.number,
	width: PropTypes.number
});

export const ViewsPropType = PropTypes.shape({
	total: PropTypes.number,
	week: PropTypes.number,
	month: PropTypes.number,
	totalCorrection: PropTypes.number,
	lastViewDate: PropTypes.string
});

export const MaterialPropType = PropTypes.shape({
	_id: PropTypes.number.isRequired,
	createDate: PropTypes.string,
	files: PropTypes.arrayOf(FilePropType).isRequired,
	name: PropTypes.string,
	player: PropTypes.string,
	publishDate: PropTypes.string,
	shortText: PropTypes.string,
	status: PropTypes.string,
	tags: PropTypes.arrayOf(PropTypes.string),
	text: PropTypes.string,
	title: PropTypes.string,
	type: PropTypes.string,
	updateDate: PropTypes.string,
	views: ViewsPropType
});

export const EntitiesPropType = PropTypes.arrayOf(EntityPropType);
export const MixedEntitiesPropType = PropTypes.arrayOf(
	PropTypes.shape({
		...baseProps,
		entityType: PropTypes.string.isRequired
	})
);

export const BreadcrumbsPropType = PropTypes.arrayOf(
	PropTypes.shape({
		title: PropTypes.string.isRequired,
		path: PropTypes.string
	}).isRequired
);

export const TextBlockPropType = PropTypes.shape({
	name: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired
});

export const PromoBlockPropType = PropTypes.shape({
	_id: PropTypes.number,
	type: PropTypes.string,
	placement: PropTypes.string,
	text: PropTypes.string
});

export const PaginationPropType = PropTypes.shape({
	total: PropTypes.number.isRequired,
	current: PropTypes.number.isRequired
});

export const PaginatedItemsPropType = PropTypes.shape({
	total: PropTypes.number.isRequired,
	items: PropTypes.array.isRequired,
	pagination: PaginationPropType.isRequired
});

export const TagPropType = PropTypes.shape({
	title: PropTypes.string.isRequired,
	url: PropTypes.string,
	_id: PropTypes.number,
	name: PropTypes.string,
	status: PropTypes.string
});

export const ThumbnailFileCropType = PropTypes.shape({
	x: PropTypes.number.isRequired,
	y: PropTypes.number.isRequired,
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired
});

const interactiveTypes = Object.values(interactiveTypesHash);

export const VariantPropType = PropTypes.shape({
	isCorrect: PropTypes.bool,
	position: PropTypes.number.isRequired,
	text: PropTypes.string.isRequired,
	score: PropTypes.string
});

export const InteractiveQuestionPropType = PropTypes.shape({
	answer: PropTypes.shape({ text: PropTypes.string }),
	image: PropTypes.shape({
		float: PropTypes.bool,
		file: FilePropType
	}),
	text: PropTypes.string.isRequired,
	variants: PropTypes.arrayOf(VariantPropType)
});

export const InteractiveResultPropType = PropTypes.shape({
	file: FilePropType,
	range: PropTypes.shape({ from: PropTypes.number, to: PropTypes.number }),
	sharingImage: FilePropType,
	text: PropTypes.string,
	forScore: PropTypes.string,
	title: PropTypes.string
});

export const InteractivePropType = PropTypes.shape({
	_id: PropTypes.number.isRequired,
	createDate: PropTypes.string,
	creator: PropTypes.number,
	editor: PropTypes.number,
	mode: PropTypes.oneOf(['list', 'slider']),
	publishDate: PropTypes.string,
	questions: PropTypes.arrayOf(InteractiveQuestionPropType),
	results: PropTypes.arrayOf(InteractiveResultPropType),
	status: StatusPropType,
	title: PropTypes.string,
	type: PropTypes.oneOf(interactiveTypes),
	updateDate: PropTypes.string
});

export const LocaleType = PropTypes.shape({
	_id: PropTypes.number,
	title: PropTypes.string,
	timezone: PropTypes.string,
	sysName: PropTypes.string
});

export const MainRubricType = PropTypes.shape({
	_id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired
});

export const EipskPropType = PropTypes.shape({
	placeId: PropTypes.number,
	organizationId: PropTypes.number
});

export const LocationPropType = PropTypes.shape({
	type: PropTypes.string,
	coordinates: PropTypes.arrayOf(PropTypes.number)
});

export const ThumbnailFilePropType = PropTypes.shape({
	_id: PropTypes.number,
	originalName: PropTypes.string,
	size: PropTypes.string,
	publicId: PropTypes.string,
	mimeType: PropTypes.string,
	format: PropTypes.string,
	meta: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string,
		author: PropTypes.string
	}),
	width: PropTypes.number,
	height: PropTypes.number,
	averageColor: PropTypes.string,
	type: PropTypes.string,
	acl: PropTypes.string
});

export const MentionedEntitiesPropType = PropTypes.shape({
	books: PropTypes.array,
	events: PropTypes.array,
	institutes: PropTypes.array,
	movies: PropTypes.array,
	objects: PropTypes.array,
	persons: PropTypes.array,
	publications: PropTypes.array
});

export const DCXType = PropTypes.shape({
	id: PropTypes.string
});

export const GenrePropType = PropTypes.shape({
	_id: PropTypes.number,
	title: PropTypes.string,
	name: PropTypes.string
});
