import _ from 'underscore';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getImageUrl } from '@culture/common-helpers/cultureUtils';

import Image from 'next/future/image';
import dynamic from 'next/dynamic';
import { ImageFormatContext } from 'contexts/ImageFormatContext';
import LazyLoadImage from 'components/LazyLoad/Image';

import PlaceholderImage from 'components/PlaceholderImage';
import styles from './styles.module.scss';

const DescriptionImage = dynamic(() => import('components/DescriptionImage'), {
	ssr: false
});

const Thumbnail = ({
	file,
	params,
	alt,
	className,
	keepRatio,
	edgesWithoutBg,
	placeholderType,
	placeholderAlign,
	preloadImage,
	behavior,
	showMeta,
	theme
}) => {
	const imageFormat = useContext(ImageFormatContext);

	const thumbnailCx = cx(
		styles.thumbnail,
		{
			[styles.keepRatio]: keepRatio,
			[styles.edgesWithoutBg]: edgesWithoutBg
		},
		styles[behavior],
		styles[`theme_${theme}`],
		className
	);

	const ratio = (params.height / params.width) * 100;
	const imageSrc = _(file).isObject()
		? getImageUrl(file, {
				format: imageFormat,
				params: {
					crop: 'fill',
					gravity: 'center',
					...params
				}
		  })
		: file;

	const imageParams =
		params?.height && params?.width
			? _(params).pick(['width', 'height', 'priority'])
			: { fill: true, ..._(params).pick(['priority']) };

	return (
		<span
			className={thumbnailCx}
			style={{ paddingBottom: keepRatio ? `${ratio}%` : null }}
		>
			{imageSrc ? (
				<LazyLoadImage
					preloadImage={preloadImage}
					imageSrc={imageSrc}
					className={styles.imgWrapper}
				>
					<Image
						className={styles.img}
						src={imageSrc}
						alt={alt || ''}
						priority={preloadImage}
						{...{ ...imageParams }}
					/>
				</LazyLoadImage>
			) : (
				<PlaceholderImage
					className={styles.placeholder}
					type={placeholderType}
					keepRatio={keepRatio}
					align={placeholderAlign}
					theme={theme}
				/>
			)}
			{showMeta && (
				<DescriptionImage
					author={file?.meta?.author}
					source={file?.meta?.source}
					description={file?.meta?.description}
				/>
			)}
		</span>
	);
};

Thumbnail.propTypes = {
	preloadImage: PropTypes.bool,
	file: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	params: PropTypes.object,
	alt: PropTypes.string,
	keepRatio: PropTypes.bool,
	edgesWithoutBg: PropTypes.bool,
	className: PropTypes.string,
	placeholderType: PropTypes.string,
	placeholderAlign: PropTypes.string,
	behavior: PropTypes.oneOf(['background', 'image']),
	showMeta: PropTypes.bool,
	theme: PropTypes.oneOf(['circle'])
};

Thumbnail.defaultProps = {
	preloadImage: false,
	params: {},
	keepRatio: false,
	edgesWithoutBg: false,
	behavior: 'background'
};

export default Thumbnail;
