import _ from 'underscore';
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from 'components/Icon';

import { PVI_NO_STYLES_CLASSNAME } from 'utils/helpers/inclusion';
import styles from './styles.module.scss';

const Button = forwardRef(
	(
		{
			title,
			icon,
			active,
			align,
			width,
			className,
			iconClassName,
			iconWrapperClassName,
			activeClassName,
			titleClassName,
			onClick,
			tooltip,
			type,
			theme,
			reverse,
			...props
		},
		ref
	) => {
		const buttonCx = cx(
			styles.button,
			{
				[styles.active]: active,
				[activeClassName]: active
			},
			styles[`theme_${theme}`],
			styles[`align_${align}`],
			styles[`width_${width}`],
			{
				[styles.reverse]: reverse
			},
			className
		);

		const iconWrapperCx = cx(styles.iconWrapper, iconWrapperClassName);

		return (
			/* eslint-disable react/button-has-type */
			<button
				type={type}
				className={buttonCx}
				onClick={onClick}
				title={tooltip}
				ref={ref}
				{...props}
			>
				{!!icon && (
					<span className={iconWrapperCx}>
						<Icon
							icon={icon}
							className={cx(styles.icon, styles[`icon_${icon}`], iconClassName)}
							useClassName={PVI_NO_STYLES_CLASSNAME}
						/>
					</span>
				)}
				{!!title && (
					<span className={cx(styles.title, titleClassName)}>{title}</span>
				)}
			</button>
			/* eslint-enable */
		);
	}
);

Button.propTypes = {
	icon: PropTypes.string,
	active: PropTypes.bool,
	onClick: PropTypes.func,
	title: PropTypes.node,
	tooltip: PropTypes.string,
	type: PropTypes.string,
	theme: PropTypes.oneOf([
		'primary',
		'contrast',
		'circleContrast',
		'darkTranslucent',
		'bright'
	]),
	align: PropTypes.oneOf(['center']),
	width: PropTypes.oneOf(['wide']),
	className: PropTypes.string,
	iconClassName: PropTypes.string,
	iconWrapperClassName: PropTypes.string,
	activeClassName: PropTypes.string,
	titleClassName: PropTypes.string,
	reverse: PropTypes.bool
};

Button.defaultProps = {
	active: false,
	onClick: _.noop,
	type: 'button'
};

export default Button;
