import _ from 'underscore';
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavigationPropType } from 'propTypes/common';

import AuthButtonContainer from 'containers/AuthButton';
import Button from 'components/Button';
import Navigation from 'components/Navigation';
import { BodyScrollLockContext } from 'contexts/BodyScrollLockContext';

import { navigationLinks } from 'utils/helpers/navigation';

import styles from './styles.module.scss';

const MobileMenu = ({
	menu,
	onLocationButtonClick,
	locationTitle,
	showActiveNavigationItem,
	onLoginClick
}) => {
	const [, toggleBodyScrollLock] = useContext(BodyScrollLockContext);
	const ref = useRef(null);
	const [userMenuIsShown, setUserMenuIsShown] = useState(null);

	useEffect(() => {
		const element = ref.current;
		toggleBodyScrollLock(element, true);

		return () => toggleBodyScrollLock(element, false);
	}, []);

	const showUserMenu = useCallback(() => setUserMenuIsShown(true), []);

	const showNavigation = useCallback((newActiveItemRef, activeItemRef) => {
		setUserMenuIsShown(false);

		const elementIsNext = (element, nextElement) => {
			if (element.nextElementSibling) {
				if (element.nextElementSibling === nextElement) {
					return true;
				}

				return elementIsNext(element.nextElementSibling, nextElement);
			}

			return false;
		};

		const newActiveItemIsNext =
			activeItemRef &&
			activeItemRef.current &&
			elementIsNext(activeItemRef.current, newActiveItemRef.current);

		if (newActiveItemIsNext) {
			ref.current.scrollTop -= activeItemRef.current.offsetHeight - 56;
		}
	}, []);

	const renderUserButton = useCallback(
		(user) => {
			const userMenu = [
				{
					_id: 1,
					title: user && user.fullName,
					children: navigationLinks.userMenu
				}
			];

			return user ? (
				<Navigation
					data={userMenu}
					type="mobile"
					showActiveItem={showActiveNavigationItem && userMenuIsShown}
					onSetActiveItem={showUserMenu}
				/>
			) : (
				<Button
					className={styles.button}
					iconWrapperClassName={styles.iconWrapper}
					onClick={onLoginClick}
					title="Войти"
					icon="login"
				/>
			);
		},
		[showActiveNavigationItem, userMenuIsShown, showUserMenu, onLoginClick]
	);

	return (
		<div className={styles.menu} ref={ref}>
			<div className={styles.block}>
				<AuthButtonContainer render={renderUserButton} />
			</div>
			<div className={styles.block}>
				<button
					type="button"
					className={styles.button}
					onClick={onLocationButtonClick}
				>
					<div className={styles.inner}>
						<span className={styles.subtitle}>Ваше местоположение</span>
						{locationTitle}
					</div>
				</button>
			</div>
			<div className={cx(styles.block, styles.block_bordered)}>
				<Navigation
					data={menu}
					showActiveItem={showActiveNavigationItem && !userMenuIsShown}
					onSetActiveItem={showNavigation}
					theme="main"
					type="mobile"
				/>
			</div>
			<div
				className={cx(styles.block, styles.block_bordered, styles.theme_simple)}
			>
				{_(navigationLinks.about).map((link) => (
					<a
						key={link.url}
						href={link.url}
						className={styles.link}
						target={link.target}
					>
						{link.title}
					</a>
				))}
			</div>
		</div>
	);
};

MobileMenu.propTypes = {
	menu: NavigationPropType.isRequired,
	onLocationButtonClick: PropTypes.func,
	locationTitle: PropTypes.string,
	showActiveNavigationItem: PropTypes.bool,
	onLoginClick: PropTypes.func.isRequired
};

MobileMenu.defaultProps = {
	onLocationButtonClick: _.noop,
	locationTitle: 'Россия',
	showActiveNavigationItem: true
};

export default MobileMenu;
