/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import _ from 'underscore';
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import cx from 'classnames';
import { NavigationPropType } from 'propTypes/common';
import Animation from 'components/Animation';
import OnlyClient from 'components/OnlyClient';
import { BodyScrollLockContext } from 'contexts/BodyScrollLockContext';
import useGlobalElementRef from 'hooks/useGlobalElementRef';

import { useIsMobile } from 'hooks/useIsMobile';
import { PVIContext } from 'contexts/PVIContext';
import ProgressBar from 'components/ProgressBar';
import Logo from './Logo';
import Menu from './Menu';
import MobileMenu from './MobileMenu';
import UserButton from './UserButton';

import styles from './styles.module.scss';

const AuthModal = dynamic(() => import('components/modals/Auth'), {
	ssr: false
});
const Search = dynamic(() => import('components/Search'), {
	ssr: false
});
const UserLocationContainer = dynamic(() => import('containers/UserLocation'), {
	ssr: false
});

const { publicRuntimeConfig } = getConfig();

const Header = ({ menu, hasTopPromoBlock, progressBarContentRef }) => {
	const headerBrandRef = useRef(null);
	const headerContainerRef = useGlobalElementRef('header');
	const [, toggleBodyScrollLock] = useContext(BodyScrollLockContext);
	const isMobile = useIsMobile();
	const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
	const [searchIsOpen, setSearchIsOpen] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [onExitedMobileMenu, setOnExitedMobileMenu] = useState(null);
	const [isShowAuthModal, setIsShowAuthModal] = useState(false);
	const [showOverlay, setShowOverlay] = useState(false);
	const [mobileMenuOffsetTop, setMobileMenuOffsetTop] = useState(0);
	const { panelActive, panelHide, panelHeight } = useContext(PVIContext);

	useEffect(() => {
		if (!isMobile && mobileMenuIsOpen && !panelActive) {
			setMobileMenuIsOpen(false);
			setShowOverlay(false);
		}
	}, [mobileMenuIsOpen, isMobile, panelActive]);

	useEffect(() => {
		toggleBodyScrollLock(headerBrandRef.current, searchIsOpen);
	}, [searchIsOpen]);

	useEffect(() => {
		const onOffsetHeader = () => {
			const bodyScrollTop = document.documentElement.scrollTop;
			const { headerHeight } = publicRuntimeConfig.features;

			const getOffsetHeight = () => {
				const promoBlockHeight = hasTopPromoBlock
					? publicRuntimeConfig.features.topPromoBlockHeight
					: 0;

				if (isMobile) {
					const scrolledHeight =
						promoBlockHeight + panelHeight - bodyScrollTop >= 0
							? promoBlockHeight + panelHeight - bodyScrollTop
							: 0;
					return headerHeight + scrolledHeight;
				}
				const promoblockScrolledHeight =
					promoBlockHeight - bodyScrollTop >= 0
						? promoBlockHeight - bodyScrollTop
						: 0;

				const panelScrolledHeight = panelHeight;
				return headerHeight + promoblockScrolledHeight + panelScrolledHeight;
			};

			const offsetHeight = getOffsetHeight();

			setMobileMenuOffsetTop(offsetHeight);
		};

		if (isMobile || panelActive) {
			onOffsetHeader();

			window.addEventListener('scroll', onOffsetHeader);
		} else {
			setMobileMenuOffsetTop(0);
		}

		return () => window.removeEventListener('scroll', onOffsetHeader);
	}, [hasTopPromoBlock, panelActive, panelHide, panelHeight, isMobile]);

	const onEnterSearchValue = useCallback((value) => {
		window.location = `/search?query=${value}`;
	}, []);

	const hideAuthModal = useCallback(() => {
		setIsShowAuthModal(false);
	}, []);

	const toggleMobileMenu = useCallback(() => {
		if (searchIsOpen && !mobileMenuIsOpen) {
			setSearchIsOpen(false);
		}

		setMobileMenuIsOpen(!mobileMenuIsOpen);
		setShowOverlay(!mobileMenuIsOpen);
	}, [searchIsOpen, mobileMenuIsOpen]);

	const showAuthModal = useCallback(() => {
		if (mobileMenuIsOpen) {
			toggleMobileMenu();

			setOnExitedMobileMenu(() => () => {
				setIsShowAuthModal(true);
				setOnExitedMobileMenu(null);
			});
		} else {
			setIsShowAuthModal(true);
		}
	}, [mobileMenuIsOpen]);

	const toggleSearch = useCallback(() => {
		if (mobileMenuIsOpen && !searchIsOpen) {
			setMobileMenuIsOpen(false);
		}

		setSearchIsOpen(!searchIsOpen);
		setSearchValue('');
		setShowOverlay(!searchIsOpen);
	}, [mobileMenuIsOpen, searchIsOpen]);

	const handleSearch = useCallback((event) => {
		setSearchValue(event.target.value);
	}, []);

	const hideOverlay = useCallback(() => {
		setShowOverlay(false);
		setMobileMenuIsOpen(false);
		setSearchIsOpen(false);
	}, []);

	const searchCx = cx(styles.search, {
		[styles.opened]: searchIsOpen
	});

	const [locationButtonElement, setLocationButtonElement] = useState(null);
	const locationButtonRef = { current: locationButtonElement };

	const [locationPopupIsOpen, setLocationPopupIsOpen] = useState(false);
	const [locationTitle, setLocationTitle] = useState('Россия');

	const onLocationButtonClick = useCallback(() => {
		hideOverlay();

		if (mobileMenuIsOpen) {
			setOnExitedMobileMenu(() => () => {
				setLocationPopupIsOpen(!locationPopupIsOpen);
				setOnExitedMobileMenu(null);
			});
		} else {
			setLocationPopupIsOpen(!locationPopupIsOpen);
		}
	}, [locationPopupIsOpen, mobileMenuIsOpen]);

	const closeLocationPopup = useCallback(() => {
		setLocationPopupIsOpen(false);
	}, []);

	const onApiLocationLoaded = useCallback((title) => {
		setLocationTitle(title);
	}, []);

	const overlayCx = cx(styles.overlay, {
		[styles.show]: showOverlay
	});

	const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);

	const toggleUserMenu = () => {
		hideOverlay();
		setUserMenuIsOpen(!userMenuIsOpen);
	};

	const closeUserMenu = () => {
		setUserMenuIsOpen(false);
	};

	return (
		<header id="header" className={styles.header}>
			<div ref={headerContainerRef} className={styles.container}>
				<div className={styles.inner}>
					<div className={styles.mobileMenuButton}>
						<UserButton
							title="Мобильное меню"
							icon="nav"
							toggle={mobileMenuIsOpen}
							active
							onClick={toggleMobileMenu}
						/>
					</div>
					<div className={styles.brand} ref={headerBrandRef}>
						<Logo />
					</div>

					<Menu
						menu={menu}
						onLocationButtonClick={onLocationButtonClick}
						locationTitle={locationTitle}
						locationButtonRef={setLocationButtonElement}
						onLoginClick={showAuthModal}
						popupIsOpen={userMenuIsOpen}
						onClick={toggleUserMenu}
						closeUserMenu={closeUserMenu}
						toggleUserMenu={toggleUserMenu}
					/>

					<UserButton
						title="Поиск"
						icon="search"
						theme="bright"
						active={searchIsOpen}
						toggle={searchIsOpen}
						onClick={toggleSearch}
						className={styles.searchButton}
					/>
					{isShowAuthModal && (
						<OnlyClient>
							<AuthModal
								hideAuthModal={hideAuthModal}
								isShowAuthModal={isShowAuthModal}
							/>
						</OnlyClient>
					)}
				</div>
			</div>
			{progressBarContentRef && (
				<ProgressBar contentRef={progressBarContentRef} />
			)}
			{searchIsOpen && (
				<div className={searchCx}>
					<div className={styles.inner}>
						<Search
							isOpen={searchIsOpen}
							onEnter={onEnterSearchValue}
							onRequestClose={toggleSearch}
							onChange={handleSearch}
							value={searchValue}
							theme="header"
						/>
					</div>
				</div>
			)}
			<Animation
				animation="slide"
				show={mobileMenuIsOpen}
				onExited={onExitedMobileMenu || _.noop}
			>
				<div
					className={styles.mobileMenu}
					style={{
						top: mobileMenuOffsetTop
					}}
				>
					<MobileMenu
						menu={menu}
						showActiveNavigationItem={mobileMenuIsOpen}
						onLoginClick={showAuthModal}
						onLocationButtonClick={onLocationButtonClick}
						locationTitle={locationTitle}
					/>
				</div>
			</Animation>

			<div
				className={overlayCx}
				onClick={hideOverlay}
				style={{
					top: panelActive && mobileMenuOffsetTop
				}}
			/>

			{locationPopupIsOpen && (
				<UserLocationContainer
					locationButtonRef={
						isMobile || panelActive ? headerBrandRef : locationButtonRef
					}
					placement={isMobile || panelActive ? 'bottom' : 'bottom-end'}
					onApiLocationLoaded={onApiLocationLoaded}
					locationPopupIsOpen={locationPopupIsOpen}
					closeLocationPopup={closeLocationPopup}
				/>
			)}
		</header>
	);
};

Header.propTypes = {
	menu: NavigationPropType.isRequired,
	hasTopPromoBlock: PropTypes.bool,
	progressBarContentRef: PropTypes.object
};

export default Header;
