import _ from 'underscore';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import VisibilitySensor from 'react-visibility-sensor';
import { anime } from 'react-anime';

const { publicRuntimeConfig } = getConfig();

const LazyLoadComponent = ({
	placeholder,
	visibleByDefault,
	children,
	onChange,
	canShow,
	animate,
	className,
	animationDuration,
	afterShow
}) => {
	const useVisibilitySensor =
		!visibleByDefault && publicRuntimeConfig.features.lazy;

	const ref = useRef(null);
	const [isVisible, setIsVisible] = useState(false);
	const waitingForAnimation = animate && !isVisible;

	const onChangeVisibility = useCallback(
		(visibility) => {
			if (visibility) {
				setIsVisible(visibility);
				onChange(visibility);
			}
		},
		[onChange]
	);

	useEffect(() => {
		if (isVisible && canShow && animate) {
			anime({
				targets: ref.current,
				opacity: 1,
				duration: animationDuration,
				easing: 'linear',
				complete: afterShow
			});
		}
	}, [isVisible, canShow, animate]);

	return useVisibilitySensor ? (
		<VisibilitySensor
			partialVisibility
			onChange={onChangeVisibility}
			active={!isVisible}
		>
			{(canShow && !waitingForAnimation) || !placeholder ? (
				<span
					className={className}
					ref={ref}
					style={{ opacity: animate ? 0 : 1 }}
				>
					{children}
				</span>
			) : (
				placeholder
			)}
		</VisibilitySensor>
	) : (
		children
	);
};

LazyLoadComponent.propTypes = {
	onChange: PropTypes.func,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	visibleByDefault: PropTypes.bool,
	canShow: PropTypes.bool,
	animate: PropTypes.bool,
	placeholder: PropTypes.node,
	animationDuration: PropTypes.number,
	afterShow: PropTypes.func
};

LazyLoadComponent.defaultProps = {
	onChange: _.noop,
	canShow: true,
	animate: true,
	visibleByDefault: false,
	animationDuration: 300,
	afterShow: _.noop
};

export default LazyLoadComponent;
