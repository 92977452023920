import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.module.scss';

const Icon = ({ icon, className, useClassName, ...props }) => {
	const iconCx = cx(styles.icon, className);

	return (
		<svg className={iconCx} {...props}>
			<use
				xmlnsXlink="http://www.w3.org/1999/xlink"
				xlinkHref={`#icon-${icon}`}
				className={useClassName}
			/>
		</svg>
	);
};

Icon.propTypes = {
	icon: PropTypes.oneOf([
		'logo',
		'esia',
		'email',
		'pro',
		'vkontakte',
		'odnoklassniki',
		'facebook',
		'twitter',
		'instagram',
		'youtube',
		'telegram',
		'tiktok',
		'share',
		'quote',
		'quoteStart',
		'yaMessenger',
		'yaMessengerColor',
		'whatsapp',
		'yaDzen',
		'podcast',
		'location',
		'user',
		'search',
		'nav',
		'close',
		'close-bold',
		'chevronLeft',
		'chevronRight',
		'chevronLeftRounded',
		'chevronRightRounded',
		'bookmark',
		'socials',
		'send',
		'clip',
		'login',
		'arrow-top',
		'arrow-right',
		'check',
		'date',
		'preloader',
		'favorite',
		'video',
		'media-tours',
		'media-infographic',
		'media-gallery',
		'media-broadcast',
		'media-events',
		'lens',
		'filter',
		'arrow-down',
		'media-performances',
		'wheelchair',
		'calendar',
		'calendar-inclusive',
		'light-arrow',
		'star',
		'ticket',
		'virtual-tour',
		'accessible',
		'pushkin',
		'headphones',
		'literature',
		'architecture',
		'music',
		'cinema',
		'museums',
		'theater',
		'lectures',
		'traditions',
		'accessibility-wheelchair',
		'accessibility-eye',
		'accessibility-ear',
		'accessibility-brain',
		'accessible-new',
		'dotsVerticall',
		'wheelchairUsers',
		'visualImpairment',
		'hearingProblems',
		'mentalDisorders',
		'print',
		'info',
		'linksQuote',
		'audio-playIcon',
		'audio-pauseIcon',
		'audio-volumeIcon',
		'audio-volumeDisableIcon',
		'audio-backButton',
		'audio-forwardButton',
		'audio-open',
		'audio-copy',
		'round-arrow-down',
		'small-arrow-cursor',
		'eye',
		'list',
		'map-pin',
		'question-mark',
		'audio-definition',
		'correct',
		'incorrect',
		'recommendation',
		'calendar-small',
		'translucentPoems',
		'microphone',
		'info-circle',
		'360',
		'record',
		'broadcast',
		'download',
		'chevronBottomRounded'
	]).isRequired,
	className: PropTypes.string,
	useClassName: PropTypes.string
};

export default Icon;
