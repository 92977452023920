import React, { memo, useCallback } from 'react';
import { anime } from 'react-anime';
import cx from 'classnames';

import Button from 'components/Button';

import useGlobalElementRef from 'hooks/useGlobalElementRef';

import styles from './styles.module.scss';

const ToTopButton = () => {
	const buttonRef = useGlobalElementRef('toTopButton');

	const onClick = useCallback(() => {
		const scrollElement =
			window.document.scrollingElement ||
			window.document.documentElement ||
			window.document.body;

		anime({
			targets: scrollElement,
			scrollTop: 0,
			duration: 500,
			easing: 'easeInOutQuad'
		});
	}, []);

	return (
		<div ref={buttonRef} className="pvi-no-styles">
			<Button
				icon="arrow-top"
				tooltip="Наверх"
				onClick={onClick}
				className={cx(styles.ToTopButton, 'pvi-no-styles')}
				iconClassName={cx(styles.ToTopButton__IconTop)}
			/>
		</div>
	);
};

export default memo(ToTopButton);
