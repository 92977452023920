import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import icon from './icons';

import styles from './styles.module.scss';

const PlaceholderImage = ({ className, keepRatio, type, align, theme }) => {
	const placeHolderCx = cx(
		styles.placeholder,
		styles[`theme_${theme}`],
		{
			[styles.keepRatio]: keepRatio
		},
		className
	);

	return (
		<div className={placeHolderCx}>
			{type && (
				<div
					className={cx(
						styles.icon,
						styles[`type_${type}`],
						styles[`align_${align}`]
					)}
				>
					{icon[type]}
				</div>
			)}
		</div>
	);
};

PlaceholderImage.propTypes = {
	className: PropTypes.string,
	align: PropTypes.oneOf(['center', 'top']),
	keepRatio: PropTypes.bool,
	type: PropTypes.oneOf(['institutes', 'persons', 'mainLogo', 'shortLogo']),
	theme: PropTypes.oneOf(['circle'])
};

PlaceholderImage.defaultProps = {
	align: 'top',
	keepRatio: false
};

export default PlaceholderImage;
