import getConfig from 'next/config';
import { movieTypesHash } from '@culture/utils/helpers/movies';
import { objectTypeHash } from '@culture/utils/helpers/objects';
import { getEntityUrl as getEntityUrlCommonHelper } from '@culture/common-helpers/cultureUtils';
import { getEntityUrl } from '@culture/utils/helpers/entities';
import { instituteTypesHash } from '@culture/utils/helpers/institutes';
import { getEmbedUrl } from '@culture/common-helpers/materials';
import { logError } from 'utils/logger';

const {
	publicRuntimeConfig: { baseUrl, services }
} = getConfig();

export const ensureProtocol = (url) => {
	const urlProtocolRegExp = /^[^:]+(?=:\/\/)/;
	let newUrl = url;

	if (!urlProtocolRegExp.test(newUrl)) {
		newUrl = `http://${newUrl}`;
	}

	return newUrl;
};

export const getHostname = (url) => {
	const domainRegex = /(?:https?:\/\/)?(?:[^@\u002F\n]+@)?(?:www\.)?([^:\u002F\n]+)/im;
	const matches = url.match(domainRegex);

	return (matches && matches.length) > 0 ? matches[1] : '';
};

export const getCanonicalUrl = ({ entity, entityType, currentPath }) => {
	let canonicalPath = '';

	if (entity && entityType) {
		canonicalPath = getEntityUrlCommonHelper(entityType, entity);
	} else {
		canonicalPath = currentPath;
	}

	const canonicalUrl = new URL(`${baseUrl}${canonicalPath}`);
	canonicalUrl.pathname = canonicalUrl.pathname.replace(/\/$/, '');
	canonicalUrl.search = '';

	return canonicalUrl.toString();
};

export const prepareEntityUrl = (entity) => {
	const { type } = entity;

	if (!type) {
		return getEntityUrl('publications', entity);
	}
	try {
		switch (type) {
			case 'broadcast':
				return getEntityUrl('broadcast', entity);
			case 'movie':
			case movieTypesHash[type]:
				return getEntityUrl('movies', entity);
			case 'institutes':
			case instituteTypesHash[type]:
				return getEntityUrl('institutes', entity);
			case 'card':
				return entity?.url;
			case 'object':
			case objectTypeHash[type]:
				return getEntityUrl('objects', entity);
			default:
				return getEntityUrl('publications', entity);
		}
	} catch (error) {
		logError(error);
		return '';
	}
};

export const checkAndEvokeEmbedUrl = (params) => {
	try {
		return getEmbedUrl(params);
	} catch {
		return '';
	}
};

export const makeBaseUrl = ({ protocol, hostname, port }) => {
	const portPart = port === 443 ? '' : `:${port}`;

	return `${protocol}//${hostname}${portPart}`;
};

export const getFullEntityUrl = (entityType, entity, options = {}) => {
	let base = '';
	const entityPath = getEntityUrl(entityType, entity, options);

	if (options.full) {
		base = makeBaseUrl(services.frontend.main);
	}

	return `${base}${entityPath}`;
};
